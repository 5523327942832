<!-- 双色球、快乐8、七乐彩、大乐透单式复式中奖计算 -->
<template>
  <div class="">
    <div class="title">
      <div :class="danfu == 0 ? 'fu1' : 'ssqfua'" @click="fu()">
        复式奖金计算
      </div>
      <div :class="danfu == 1 ? 'fu1' : 'ssqfub'" @click="dan()">
        胆拖奖金计算
      </div>
      <div class="clear"></div>
    </div>
    <div :class="danfu == 0 ? 'fu' : 'no'">
      <div :class="fu3[0].wanfa == 'klb' ? 'xuan' : 'no'">
        <div class="xuan1">投注方式：</div>
        <div class="xuan3">
          <li>
            <select style="width: 60px" ref="xuan">
              <option :value="item.val" v-for="(item, i) in xuan" :key="i">
                {{ item.title }}
              </option>
            </select>
          </li>
        </div>
      </div>
      <div class="xuan">
        <div class="xuan1">我的投注：</div>
        <div class="xuan2">{{ fu3[0].xh }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xu()" style="width: 60px" ref="xh">
              <option :value="parseInt(item) + parseInt(gs)" v-for="(item, i) in red - gs" :key="i">
                {{ parseInt(item) + parseInt(gs) }}个
              </option>
            </select>
          </li>
        </div>
        <div :class="fu3[0].wanfa == 'klb' || fu3[0].wanfa == 'qlc' ? 'no' : 'xuan2'
          ">
          {{ fu3[0].xl }}:
        </div>
        <div :class="
            fu3[0].wanfa == 'klb' || fu3[0].wanfa == 'qlc' ? 'no' : 'xuan3'
          ">
          <li>
            <select style="width: 60px" ref="xl">
              <option :value="fu3[0].wanfa=='dlt'&&danfu==0?item+1:item" v-for="(item, i) in lan" :key="i">
                {{ fu3[0].wanfa=='dlt'&&danfu==0?item+1:item }}个
              </option>
            </select>
          </li>
        </div>
      </div>
      <div class="zhong">
        <div class="xuan1">我的命中：</div>
        <div class="xuan2">{{ fu3[0].zh }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xu()" style="width: 60px" ref="zh">
              <option :value="item - 1" v-for="(item, i) in fu3[0].wanfa == 'klb'
                  ? parseInt(klbz)
                  : parseInt(gs) + 1" :key="i">
                <!-- fu3[0].wanfa == 'klb'
                  ? parseInt(klbz)
                  : parseInt(gs) + 1 -->
                {{ item - 1 }}个
              </option>
            </select>
          </li>
        </div>
        <div :class="fu3[0].wanfa == 'klb' ? 'no' : 'xuan2'">
          {{ fu3[0].zl }}:
        </div>
        <div :class="fu3[0].wanfa == 'klb' ? 'no' : 'xuan3'">
          <li>
            <select style="width: 60px" ref="zl">
              <option :value="item - 1" v-for="(item, i) in fu3[0].wanfa=='dlt'&&danfu==0?3:zl" :key="i">
                {{ item - 1 }}个
              </option>
            </select>
          </li>
        </div>
      </div>

      <div :class="money2[0] == null ? 'no' : 'money'">{{ money2[0] }} +</div>
      <div :class="money2[1] == null ? 'no' : 'money'">{{ money2[1] }} +</div>
      <div :class="money2[2] == null ? 'no' : 'money'">{{ money2[2] }} +</div>
      <div class="money">中{{ money.toFixed(1) }}元</div>
      <div class="suan" @click="suan()">奖金计算</div>
    </div>

    <div :class="danfu == 1 ? 'dan' : 'no'">
      <div :class="fu3[0].wanfa == 'klb' ? 'xuan' : 'no'">
        <div class="xuan1">投注方式：</div>
        <div class="xuan3">
          <li>
            <select style="width: 60px" ref="xuan1">
              <option :value="item.val" v-for="(item, i) in xuan1" :key="i">
                {{ item.title }}
              </option>
            </select>
          </li>
        </div>
      </div>
      <div class="xuan">
        <div class="xuan1">我的投注：</div>
        <div class="xuan2">{{ fu3[0].xd }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xd1()" style="width: 60px" ref="xd">
              <option :value="item" v-for="(item, i) in gs - 1" :key="i">
                {{ item }}个
              </option>
            </select>
          </li>
        </div>
        <div class="xuan2">{{ fu3[0].xt }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xd1()" style="width: 60px" ref="xt">
              <option :value="item + (gs - xd)" v-for="(item, i) in red - (gs - xd) - xd" :key="i">
                {{ item + (gs - xd) }}个
              </option>
            </select>
          </li>
        </div>
        <div :class="
            danfu == 1 && (fu3[0].wanfa == 'klb' || fu3[0].wanfa == 'qlc')
              ? 'no'
              : 'xuan2'
          ">
          {{ fu3[0].xtl }}:
        </div>
        <div :class="
            danfu == 1 && (fu3[0].wanfa == 'klb' || fu3[0].wanfa == 'qlc')
              ? 'no'
              : 'xuan3'
          ">
          <li>
            <select @click="xd1()" style="width: 60px" ref="xtl">
              <option :value="fu3[0].wanfa=='dlt'&&danfu==1?item-1:item"
                v-for="(item, i) in fu3[0].wanfa=='dlt'&&danfu==1?zl:lan" :key="i">
                {{ fu3[0].wanfa=='dlt'&&danfu==1?item-1:item }}个
              </option>
            </select>
          </li>
        </div>
        <div :class="
        danfu == 1 && fu3[0].wanfa == 'dlt'
          ? 'xuan2'
          : 'no'
      ">
          {{ fu3[0].xtl2 }}:
        </div>
        <div :class="
        danfu == 1 && fu3[0].wanfa == 'dlt' 
          ? 'xuan3'
          : 'no'
      ">
          <li>
            <select @click="xd1()" style="width: 60px" ref="xtl2">
              <option :value="item+1" v-for="(item, i) in lan-xtl" :key="i">
                {{ item+1 }}个
              </option>
            </select>
          </li>
        </div>
      </div>
      <div class="zhong">
        <div class="xuan1">我的命中：</div>
        <div class="xuan2">{{ fu3[0].zd }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xd1()" style="width: 60px" ref="zd">
              <option :value="item - 1" v-for="(item, i) in parseInt(xd) + 1" :key="i">
                {{ item - 1 }}个
              </option>
            </select>
          </li>
        </div>
        <div class="xuan2">{{ fu3[0].zt }}:</div>
        <div class="xuan3">
          <li>
            <select @click="xd1()" style="width: 60px" ref="zt">
              <option :value="item - 1"
                v-for="(item, i) in (fu3[0].wanfa == 'ssq' ||fu3[0].wanfa == 'qlc'||fu3[0].wanfa == 'dlt') && danfu == 1? (gs-zd<xt?gs-zd+1:parseInt(xt)+1): fu3[0].wanfa == 'klb' && danfu == 1 ? parseInt(zd) + parseInt(xt) > 20? 20 - zd + 1 : parseInt(xt) + 1: parseInt(zd) + parseInt(xt) < 20 ? parseInt(xt) + 1: 20 - zd + 1"
                :key="i">
                {{ item - 1 }}个
              </option>
            </select>
          </li>
        </div>
        <div :class="danfu == 1 && fu3[0].wanfa == 'klb' ? 'no' : 'xuan2'">
          {{ fu3[0].ztl }}:
        </div>
        <div :class="danfu == 1 && fu3[0].wanfa == 'klb' ? 'no' : 'xuan3'">
          <li>
            <select @click="xd1()" style="width: 70px" ref="ztl">
              <option :value="fu3[0].wanfa == 'qlc' && danfu == 1 ? item.val: item - 1"
                v-for="(item, i) in fu3[0].wanfa == 'qlc' && danfu == 1? qlczl: (fu3[0].wanfa == 'dlt' && danfu == 1?parseInt(xtl)+1:zl)"
                :key="i">
                {{ fu3[0].wanfa == 'qlc' && danfu == 1 ? item.title: item - 1 }}{{fu3[0].wanfa == 'qlc' && danfu == 1?
                '': '个' }}
              </option>
            </select>
          </li>
        </div>
        <div :class="danfu == 1 && fu3[0].wanfa == 'dlt' ? 'xuan2' : 'no'">
          {{ fu3[0].ztl2 }}:
        </div>
        <div :class="danfu == 1 && fu3[0].wanfa == 'dlt' ? 'xuan3' : 'no'">
          <li>
            <select @click="xd1()" style="width: 70px" ref="ztl2">
              <option :value=" item - 1" v-for="(item, i) in ztl=='0'?3:2" :key="i">
                {{ item - 1 }}个
              </option>
            </select>
          </li>
        </div>
      </div>
      <div :class="money2[0] == null ? 'no' : 'money'">{{ money2[0] }} +</div>
      <div :class="money2[1] == null ? 'no' : 'money'">{{ money2[1] }} +</div>
      <div :class="money2[2] == null ? 'no' : 'money'">{{ money2[2] }} +</div>
      <div class="money">中{{ money }}元</div>
      <div class="suan" @click="suan()">奖金计算</div>
    </div>

    <!-- <div class="fu" style="height: 50px">
      <div class="xuan3">
        <div v-for="(item, i) in list" :key="i">
          <div>{{ item.title }}{{ item.val }}{{ item.mon }}</div>
        </div>
      </div>
    </div> -->
    <div class="fu">
      说明：本计算器计算的奖金仅供参考，如有偏差，请以彩票实际的中奖金额为准！
    </div>
    <div class="fu">
      <img :src="fu3[0].pic" alt="" />
    </div>
  </div>
</template>

<script>
  import api from "@/js/mpublic.js"; //js文件引入，注意路径
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  //import api from '@/js/method'
  // (fu3[0].wanfa == 'ssq' ||fu3[0].wanfa == 'qlc'||fu3[0].wanfa == 'dlt') && danfu == 1? zd > xt ? gs - zd + 1 : xt <= gs ? parseInt(xt) + 1 : gs - zd + 1: fu3[0].wanfa == 'klb' && danfu == 1 ? parseInt(zd) + parseInt(xt) > 20? 20 - zd + 1 : parseInt(xt) + 1: parseInt(zd) + parseInt(xt) < 20 ? parseInt(xt) + 1: 20 - zd + 1" :key="i">
  //  (fu3[0].wanfa == 'ssq' ||fu3[0].wanfa == 'qlc'||fu3[0].wanfa == 'dlt') && danfu == 1? (zd >= xt ? gs - zd + 1 : xt <= gs ? parseInt(xt) + 1 : gs - zd + 1): fu3[0].wanfa == 'klb' && danfu == 1 ? parseInt(zd) + parseInt(xt) > 20? 20 - zd + 1 : parseInt(xt) + 1: parseInt(zd) + parseInt(xt) < 20 ? parseInt(xt) + 1: 20 - zd + 1"
  export default {
    name: "",
    components: {},
    data() {
      // 数据源
      return {
        red: 1,
        lan: 1,
        gs: 1,
        xh: 0,
        xl: 0,
        zh: 0,
        zl: 2,
        xd: 1,
        xt: 1,
        zd: 0,
        zt: 0,
        klbz: 1,
        xtl: 0,
        ztl:0,
        qlczl: [
          { title: "未中", val: 0 },
          { title: "胆码中", val: 1 },
          { title: "拖码中", val: 2 },
        ],
        xuan: [
          { title: "选一", val: 1 },
          { title: "选二", val: 2 },
          { title: "选三", val: 3 },
          { title: "选四", val: 4 },
          { title: "选五", val: 5 },
          { title: "选六", val: 6 },
          { title: "选七", val: 7 },
          { title: "选八", val: 8 },
          { title: "选九", val: 9 },
          { title: "选十", val: 10 },
        ],
        xuan1: [
          { title: "选二", val: 2 },
          { title: "选三", val: 3 },
          { title: "选四", val: 4 },
          { title: "选五", val: 5 },
          { title: "选六", val: 6 },
          { title: "选七", val: 7 },
          { title: "选八", val: 8 },
          { title: "选九", val: 9 },
          { title: "选十", val: 10 },
        ],
        pic: [{ ssq: "", klb: "", qlc: "", dlt: "" }],
        danfu: [0],
        list: [],
        money: 0,
        money2: [],
      };
    },
    mounted() {
      if (this.fu3[0].wanfa == "ssq") {
        this.red = 33;
        this.lan = 16;
        this.gs = 6;
      } else if (this.fu3[0].wanfa == "klb") {
        this.red = 80;
        if (this.danfu == 1) {
          this.gs = this.$refs.xuan1.value;
        }
      } else if (this.fu3[0].wanfa == "qlc") {
        this.red = 30;
        this.gs = 7;
        this.zl = 2;
      } else if (this.fu3[0].wanfa == "dlt") {
        this.red = 35;
        this.gs = 5;
        this.lan = 11;
        this.zl = 2;
      }
    },
    computed: {},
    methods: {
      // 函数体部分，js的主要逻辑控制
      fu() {
        this.danfu = 0;
        this.money = 0;
        this.money2 = [];
        if (this.fu3[0].wanfa == "ssq") {
          this.red = 33;
          this.lan = 16;
          this.gs = 6;
        } else if (this.fu3[0].wanfa == "klb") {
          this.red = 80;
          this.gs = this.$refs.xuan.value;
        } else if (this.fu3[0].wanfa == "qlc") {
          this.red = 30;
          this.gs = 7;
        }
      },
      dan() {
        this.danfu = 1;
        this.money = 0;
        this.money2 = [];
      },
      //X, X, X, ssq, xd, xt, xl, zd, zt, zl
      //xuan, X, X, klb, xd, xt, X, zd, zt, X
      //X, X ,X, qlc, xd, xt, X, zd, zt, zl(zl=0未中特号，zl=1胆码中特号，zl=2拖码中特号)
      //xuan, xtl, dl, dlt, xd, xt, xl, zd, zt, zl(dl=0后区未中，dl=1后区中一个，dl=2后区中两个)（xl选后区胆码个数 0代表没选后区胆码，1代码选一个后区胆码，zl代表后区胆码中未中，0代表未中，1代表中）
      //xuan, xtl2, dl, dlt, xd, xt, xl, zd, zt, zl(dl=0后区未中，dl=1后区中一个，dl=2后区中两个)（xl选后区胆码个数 0代表没选后区胆码，1代码选一个后区胆码，zl代表后区胆码中未中，0代表未中，1代表中）
      suan() {
        this.money = [];
        this.money2 = [];
        this.list = [];
        if (this.danfu == 0) {
          var ssqxh = this.$refs.xh.value;
          var ssqxl = this.$refs.xl.value;
          var ssqzh = this.$refs.zh.value;
          var ssqzl = this.$refs.zl.value;
          var ssqxuan = this.$refs.xuan.value;
          // var ssqfu2=[]
          if (this.fu3[0].wanfa == "ssq") {
            var ssqfu = api.fu(1, "ssq", ssqxh, ssqxl, ssqzh, ssqzl);
            this.fz("ssq", ssqfu);
            this.money1();
          } else if (this.fu3[0].wanfa == "klb") {
            var klbfu = api.fu(ssqxuan, "klb", ssqxh, ssqxl, ssqzh, ssqzl);
            this.fz("klb", klbfu);
            this.money1();
          } else if (this.fu3[0].wanfa == "qlc") {
            var qlcfu = api.fu(ssqxuan, "qlc", ssqxh, ssqxl, ssqzh, ssqzl);
            console.log(qlcfu)
            this.fz("qlc", qlcfu);
            this.money1();
          }
          else if (this.fu3[0].wanfa == "dlt") {
            var dltfu = api.fu(ssqxuan, "dlt", ssqxh, ssqxl, ssqzh, ssqzl);
            this.fz("dlt", dltfu);
            this.money1();
          }
        } else if (this.danfu == 1) {
          var ssqxd = this.$refs.xd.value;
          var ssqxt = this.$refs.xt.value;
          var ssqzd = this.$refs.zd.value;
          var ssqzt = this.$refs.zt.value;
          var ssqxtl = this.$refs.xtl.value;
          var ssqztl = this.$refs.ztl.value;
          var ssqxuan1 = this.$refs.xuan1.value;
          if (this.fu3[0].wanfa == "ssq") {
            var ssqdan = api.dan(
              1,
              1,
              1,
              "ssq",
              ssqxd,
              ssqxt,
              ssqxtl,
              ssqzd,
              ssqzt,
              ssqztl
            );
            this.fz("ssq", ssqdan);
            this.money1();
          } else if (this.fu3[0].wanfa == "klb") {
            var klbdan = api.dan(
              ssqxuan1,
              1,
              1,
              "klb",
              ssqxd,
              ssqxt,
              1,
              ssqzd,
              ssqzt,
              1
            );
            this.fz("klb", klbdan);
            this.money1();
          } else if (this.fu3[0].wanfa == "qlc") {
            var qlcdan = api.dan(
              ssqxuan1,
              1,
              1,
              "qlc",
              ssqxd,
              ssqxt,
              1,
              ssqzd,
              ssqzt,
              ssqztl
            );
            this.fz("qlc", qlcdan);
            this.money1();
          } else if (this.fu3[0].wanfa == "dlt") {
            var ssqxtl2 = this.$refs.xtl2.value;
          var ssqztl2 = this.$refs.ztl2.value;
          var dl=parseInt(ssqztl2)+parseInt(ssqztl)
            var dltdan = api.dan(
              ssqxuan1,
              ssqxtl2,
              dl,
              "dlt",
              ssqxd,
              ssqxt,
              ssqxtl,
              ssqzd,
              ssqzt,
              ssqztl
            );

            this.fz("dlt", dltdan);
            this.money1();
          }
        }
      },
      mon(wanfa, title, val) {
        if (wanfa == "ssq") {
          if (title == "01" || title == "11" || title == "21") {
            return val * 5;
          } else if (title == "31" || title == "40") {
            return val * 10;
          } else if (title == "41" || title == "50") {
            return val * 200;
          } else if (title == "51") {
            return val * 3000;
          } else if (title == "60") {
            return "er";
          } else if (title == "61") {
            return "yi";
          }
        } else if (wanfa == "klb") {
          var klbxuan = -1;
          if (this.danfu == 0) {
            klbxuan = this.$refs.xuan.value;
          } else {
            klbxuan = this.$refs.xuan1.value;
          }
          if (klbxuan == 1) {
            return val * 4.6;
          } else if (klbxuan == 2) {
            if (title == "2") {
              return val * 19;
            }
          } else if (klbxuan == 3) {
            if (title == "2") {
              return val * 3;
            } else if (title == "3") {
              return val * 53;
            }
          } else if (klbxuan == 4) {
            if (title == "2") {
              return val * 3;
            } else if (title == "3") {
              return val * 5;
            } else if (title == "4") {
              return val * 100;
            }
          } else if (klbxuan == 5) {
            if (title == "3") {
              return val * 3;
            } else if (title == "4") {
              return val * 21;
            } else if (title == "5") {
              return val * 1000;
            }
          } else if (klbxuan == 6) {
            if (title == "3") {
              return val * 3;
            } else if (title == "4") {
              return val * 10;
            } else if (title == "5") {
              return val * 30;
            } else if (title == "6") {
              return val * 3000;
            }
          } else if (klbxuan == 7) {
            if (title == "4") {
              return val * 4;
            } else if (title == "5") {
              return val * 28;
            } else if (title == "6") {
              return val * 288;
            } else if (title == "7") {
              return val * 10000;
            } else if (title == "0") {
              return val * 2;
            }
          } else if (klbxuan == 8) {
            if (title == "4") {
              return val * 3;
            } else if (title == "5") {
              return val * 10;
            } else if (title == "6") {
              return val * 88;
            } else if (title == "7") {
              return val * 800;
            } else if (title == "8") {
              return val * 50000;
            } else if (title == "0") {
              return val * 2;
            }
          } else if (klbxuan == 9) {
            if (title == "4") {
              return val * 3;
            } else if (title == "5") {
              return val * 5;
            } else if (title == "6") {
              return val * 20;
            } else if (title == "7") {
              return val * 200;
            } else if (title == "8") {
              return val * 2000;
            } else if (title == "9") {
              return val * 300000;
            } else if (title == "0") {
              return val * 2;
            }
          } else if (klbxuan == 10) {
            if (title == "5") {
              return val * 3;
            } else if (title == "6") {
              return val * 5;
            } else if (title == "7") {
              return val * 80;
            } else if (title == "8") {
              return val * 800;
            } else if (title == "9") {
              return val * 8000;
            } else if (title == "10") {
              return "yi";
            } else if (title == "0") {
              return val * 2;
            }
          }
        } else if (wanfa == "qlc") {
          if (title == "40") {
            return val * 5;
          } else if (title == "41") {
            return val * 10;
          } else if (title == "50") {
            return val * 50;
          } else if (title == "51") {
            return val * 200;
          } else if (title == "60") {
            return "san";
          } else if (title == "61") {
            return "er";
          } else if (title == "70") {
            return "yi";
          }
        } else if (wanfa == "dlt") {
          if (title == "02" || title == "21" || title == "12" || title == "30") {
            return val * 5
          } else if (title == "31" || title == "22") {
            return val * 15
          } else if (title == "40") {
            return val * 100
          } else if (title == "32") {
            return val * 200
          } else if (title == "41") {
            return val * 300
          } else if (title == "42") {
            return val * 3000
          } else if (title == "50") {
            return val * 10000
          } else if (title == "51") {
            return "er"
          } else if (title == "52") {
            return "yi"
          }

        }
      },
      money1() {
        var mm = 0;
        if (this.list != [] || this.list != "") {
          for (var a = 0; a < this.list.length; a++) {
            if (typeof this.list[a].mon == "number") {
              mm += this.list[a].mon;
            } else if (this.list[a].mon == "san") {
              this.money2[2] = "三等奖浮动奖金";
            } else if (this.list[a].mon == "er") {
              this.money2[1] = "二等奖浮动奖金";
            } else if (this.list[a].mon == "yi") {
              this.money2[0] = "一等奖浮动奖金";
            }
          }
        }
        this.money = mm;
      },
      xd1() {
        if (this.danfu == 1 && this.fu3[0].wanfa == "ssq") {
          this.xd = this.$refs.xd.value;
          this.xt = this.$refs.xt.value;
          this.zd = this.$refs.zd.value;
          this.zt = this.$refs.zt.value;
        } else if (this.danfu == 1 && this.fu3[0].wanfa == "klb") {
          this.gs = this.$refs.xuan1.value;
          this.xd = this.$refs.xd.value;
          this.xt = this.$refs.xt.value;
          this.zd = this.$refs.zd.value;
          this.zt = this.$refs.zt.value;
        } else if (this.danfu == 1 && this.fu3[0].wanfa == "qlc") {
          this.gs = 7;
          this.xd = this.$refs.xd.value;
          this.xt = this.$refs.xt.value;
          this.zd = this.$refs.zd.value;
          this.zt = this.$refs.zt.value;
          this.zl = 2;
          if (this.xd == this.zd) {
            if (this.qlczl[1].val == 1) {
              this.qlczl.splice(1, 1)
            }
          } else if (this.zt == this.xt) {
            if (this.qlczl[2].val == 2) {
              this.qlczl.splice(2, 1)
            }

          } else {
            var obj =
              [
                { title: "未中", val: 0 },
                { title: "胆码中", val: 1 },
                { title: "拖码中", val: 2 },
              ]
            this.qlczl = obj
          }

        } else if (this.danfu == 1 && this.fu3[0].wanfa == "dlt") {
          this.gs = 5;
          this.xd = this.$refs.xd.value;
          this.xt = this.$refs.xt.value;
          this.zd = this.$refs.zd.value;
          this.zt = this.$refs.zt.value;
          this.xtl = this.$refs.xtl.value;
          this.ztl = this.$refs.ztl.value;
          this.zl = 2;

        }
      },
      xu() {
        if (this.fu3[0].wanfa == "klb" && parseInt(this.$refs.xh.value) < 20) {
          this.klbz = parseInt(this.$refs.xh.value) + parseInt(1);
        } else {
          this.klbz = 21;
        }
        if (this.fu3[0].wanfa == "klb") {
          this.gs = this.$refs.xuan.value;
        }
        if (this.fu3[0].wanfa == "qlc") {
          this.gs = 7;
        }
      },
      fz(wanfa, data) {
        if (data != 0) {
          var ax = Object.entries(data[0]);
          for (var a = 0; a < ax.length; a++) {
            if (ax[a][1] > 0) {
              var jin = this.mon(wanfa, ax[a][0], ax[a][1]);
              var obj = {
                title: ax[a][0],
                val: ax[a][1],
                mon: jin,
                //}
              };
              this.list.push(obj);
            }
          }
        }
      },
    },
    watch: {
      danfu() {
        if (this.danfu == 1 && this.fu3[0].wanfa == "klb") {
          this.gs = this.$refs.xuan1.value;
        }
      },
    },
    props: ["fu3"],
  };
</script>

<style scoped>
  img {
    width: 100%;
  }

  .title {
    width: 30%;
    margin: 0 auto;
  }

  .ssqfu {
    width: 100%;
  }

  .ssqfua {
    width: 48%;
    float: left;
    margin-left: 1%;
    font-size: 16px;
    border: 1px solid rgb(170, 169, 169);
  }

  .ssqfub {
    width: 48%;
    float: left;
    margin-left: 1%;
    font-size: 16px;
    border: 1px solid rgb(170, 169, 169);
  }

  .fu {
    width: 50%;
    margin: 0 auto;
    margin-top: 10px;
    border: 1px solid rgb(212, 211, 211);
  }

  .dan {
    width: 50%;
    margin: 0 auto;
    margin-top: 10px;
    border: 1px solid rgb(212, 211, 211);
  }

  /* .xuan {
  border: 1px solid rgb(170, 169, 169);
} */
  .xuan1 {
    font-weight: bold;
    display: inline-block;
  }

  .xuan2,
  .xuan3 {
    display: inline-block;
  }

  li {
    list-style-type: none;
  }

  .suan {
    width: 20%;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 17px;
    font-weight: bold;
    background-color: red;
    color: aliceblue;
  }

  .money {
    display: inline-block;
    color: red;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }

  .fu1 {
    width: 48%;
    float: left;
    margin-left: 1%;
    font-size: 16px;
    border: 1px solid rgb(170, 169, 169);
    background-color: blue;
    color: aliceblue;
  }

  .clear {
    clear: both;
  }

  .no {
    display: none;
  }
</style>